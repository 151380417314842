import React from 'react';
import ReactDOM from 'react-dom';
import '@guestyci/foundation/style/styles.css';

import './overrides.css';

const versions = ['v1', 'v2'];
const threeDSPages = ['success', 'failure'];

const getVersion = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const version =
    process.env.REACT_APP_VERSION || searchParams.get('version') || 'v1';

  if (!versions.includes(version)) {
    throw new Error(`Incorrect version: ${version}`);
  }

  return version;
};

const get3DSPage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const threeDSPage = searchParams.get('3ds');

  if (threeDSPage && !threeDSPages.includes(threeDSPage)) {
    throw new Error(`Incorrect 3DS page: ${threeDSPage}`);
  }

  return threeDSPage;
};

const getPage = () => {
  return get3DSPage() || getVersion();
};

import(`./app/${getPage()}`).then(({ default: App }) => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
});
